













import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import Awards from '@/components/Awards.vue';

@Component({
  components: {
    Awards,
  },
})
export default class ThankYou extends Mixins(View) {
  mounted() {
    let scr: HTMLScriptElement | null = document.getElementById('scrReaLeads') as HTMLScriptElement | null;
    if (scr) scr.remove();

    setTimeout(() => {
      scr = document.createElement('script');
      scr.type = 'text/javascript';
      scr.async = true;
      scr.defer = true;
      scr.text = 'REA.track_conversion("20538");';
      scr.id = 'scrReaLeads';

      scr.onload = () => {
        console.log('Core Logic Integration Loaded');
      };

      document.body.append(scr);
    }, 500);
  }
}
